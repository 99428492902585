import { render, staticRenderFns } from "./ValidationDemo.vue?vue&type=template&id=485ac1a4&scoped=true&"
import script from "./ValidationDemo.vue?vue&type=script&lang=js&"
export * from "./ValidationDemo.vue?vue&type=script&lang=js&"
import style0 from "./ValidationDemo.vue?vue&type=style&index=0&id=485ac1a4&scoped=true&lang=css&"
import style1 from "./ValidationDemo.vue?vue&type=style&index=1&id=485ac1a4&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../.yarn/$$virtual/vue-loader-virtual-2fffd51942/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-bc7d4c63e0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485ac1a4",
  null
  
)

export default component.exports