<script>
import ModelMixin from './ModelMixin';
// import UiFormItem from './UIFormItem';
import Component, { mixins } from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';

@Component({
  props: {
    value: {
      type: Number,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  components: {
    ValidationProvider,
  },
})
export default class UIRange extends mixins(ModelMixin) {
  editing = false
  get relativeValue () { // linear mapping to a normalized 0-100 for custom min/max range
    return (this.internalValue - this.attrs.min) * (100 - 0) / (this.attrs.max - this.attrs.min) + 0;
  }

  get barStyle () {
    return {
      width: `${Math.round(this.relativeValue)}%`,
    };
  }

  get thumbStyle () {
    return {
      left: `calc(${Math.round(this.relativeValue)}%)`,
    };
  }

  get attrs () {
    return {
      min: '0',
      max: '1',
      step: '0.01',
      ...this.$attrs,
    };
  }

  updateInternalValue (val) {
    if (!this.editing) this.internalValue = val || 0;
  }

  onInput (e) {
    this.editing = true;
    this.$listeners.input && this.$listeners.input(e);
  }

  onChange (e) {
    this.editing = false;
    this.$listeners.change && this.$listeners.change(e);
  }

  get listeners () {
    return {
      ...this.$listeners,
      input: this.onInput,
      change: this.onChange,
    };
  }
}
</script>

<template>
  <ValidationProvider tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <div class="ui-range">
        <div class="inputwrapper">
          <input class="inputrange" type="range" v-model.number="internalValue" v-on="listeners" v-bind="attrs">
          <i class="thumb" :style="thumbStyle"></i>
          <i class="volumebar" :style="barStyle"></i>
          <i class="backgroundbar"></i>
        </div>
    </div>
    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<style lang="postcss" scoped>
@import "../../styles";

.ui-range {
  display: flex;
  min-width: 100%;
  & > * + * {
    margin-left: 1.3rem;
  }
  align-items: center;
  & .inputwrapper {
    position: relative;
    flex: 1 1 auto;
    left: 1rem;
    margin-right: 1rem;
  }
  & .inputrange {
    position: absolute;

    appearance: none;
    left: -1.5rem;
    top: -1.25rem;
    width: calc(100% + 3rem);
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &::-webkit-slider-thumb {
      height: 3rem;
      width: 3rem;

      border-radius: 100%;
      appearance: none;
      background: var(--colorFjord);
    }
    &::-moz-range-thumb {
      height: 3rem;
      width: 3rem;

      border-radius: 100%;
      appearance: none;
      background: var(--colorFjord);
    }
  }
  & .thumb {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    background-color: var(--colorFjord);
    display: block;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: transform 0.15s ease-in-out;
  }

  & .volumebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--colorFjord);
    display: block;
    height: 0.5rem;
    border-radius: 2rem;
  }

  &:hover {
    & .backgroundbar {
      background-color: var(--colorGeyser);
    }
    & .thumb {
      transform: translate(-50%, -50%) scale(1.1, 1.1);
    }
  }
  & .backgroundbar {
    background-color: var(--colorCasper);
    display: block;
    height: 0.5rem;
    border-radius: 2rem;
    transition: background-color 0.25s ease-in-out;
  }
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}
</style>
