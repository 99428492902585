<script>
/* eslint-disable no-console */
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import Component from 'vue-class-component';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIInput from '@/core/shared/components/ui/UIInput';
import UICheckbox from '@/core/shared/components/ui/UICheckbox';
import UIMultiselect from '@/core/shared/components/ui/UIMultiselect';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIRadio from '@/core/shared/components/ui/UIRadio';
import AddressInputs from '@/core/shared/components/AddressInputs';
import UIRange from '@/core/shared/components/ui/UIRange';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UITimepicker from '@/core/shared/components/ui/UITimepicker';
import UIDatepicker from '@/core/shared/components/ui/UIDatepicker';
import UIBirthdatePicker from '@/core/shared/components/ui/UIBirthdatePicker';
import UIFileUpload from '@/core/shared/components/ui/UIFileUpload';
import UIRadioThumbnail from '@/core/shared/components/ui/UIRadioThumbnail';
import FileHelper from '@/core/shared/helpers/FileHelper';

// Import components and methods from vee-validate
import { ValidationObserver, ValidationProvider, setInteractionMode, extend } from 'vee-validate';
import FormHelper from '@/core/shared/helpers/FormHelper.js';
import { stringify } from '../core/shared/helpers/url-helper';

@Component({
  components: { ValidationObserver, ValidationProvider, UIInput, UISelect, UICheckbox, AddressInputs, UIMultiselect, ContentBlock, UIFormItem, UIRadio, UIRange, UISwitch, UITimepicker, UIDatepicker, UIBirthdatePicker, UIFileUpload, UIRadioThumbnail },
  methods: {
    // This method validates explicitly on submit, regardless of interaction method.
    onSubmit () {
      this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
        // console.log('Form is valid:', isValid);
        // console.log(errors);
      });
    },

    /**
     * This method indicates how field and form flags can be accessed.
     *    Available flags are:
     *    valid/invalid, changed, touched/untouched, pristine/dirty, pending, required, validated, passed/failed
     */
    dirtyFields () {
      // console.log(this.formHelper.isDirty);
      // console.log(this.formHelper.getDirty);
    },

    printFormData () {
      if (this.formData.waiversRequired) {
        alert(stringify(this.formData));
      }
    },
  },
  props: {
    value: {},
  },
})

export default class ValidationDemo extends Vue {
  errors = [''];

  setup (props, { emit }) {
    const datePickerOptions = {
      altFormat: 'F d, Y',
    };
    return datePickerOptions;
  }

  formHelper;
  mounted () {
    // console.log('passed:', this.$refs.observer);
    this.formHelper = new FormHelper(this.$refs.observer);
  }

  vmodelPlaceholder = '';
  radioValue = 'cheese';
  toggleSelected (choice) {
    this.radioValue = choice;
  }

  get countries () {
    var allCountries = this.$store.getters['entities/Country/all']();
    return allCountries;
  }

  localOrganization = {
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  }

  get fullAddress () {
    const {
      address,
      address2,
      city,
      state,
      postalCode,
      country,
    } = this.localOrganization;
    return {
      address,
      address2,
      city,
      state,
      postalCode,
      country,
    };
  }

  formData = {
    name: '',
    email: '',
    password: '',
    gender: '',
    acceptsTerms: false,
    tags: [],
    waiversRequired: false,
  };

  imageUrl = '';

  async imageChange ([file]) {
    this.imageUrl = await FileHelper.encodeImage(file);
  }
};

/**
 * Sets a page-scoped validation interaction mode. (default: aggressive)
 * Options are:
 *  Aggressive: Validation triggered on key press.
 *  Passive:    Validation triggered when form submitted.
 *  Lazy:       Validation triggered when user leaves input.
 *  Eager:      Combination of aggressive and lazy, it first validates when user leaves the input then if the input is
 *              invalid it will behave aggressively until the input is valid again and it will go back to being lazy.
 */
setInteractionMode('lazy');

/**
 * Local custom function pair to demonstrate the possibility of adding custom validation rules on a per-page basis
 */
function isItChristmas () {
  const d = new Date();
  return (d.getMonth() === 12 && d.getDate() === 25);
};
extend('customLocal', {
  validate (value) {
    // Returns true if input is "Christmas" or on Christmas day
    return value === 'Christmas' || isItChristmas();
  },
  message: 'It\'s not "Christmas" yet..',
});
</script>

<template>
  <div>
  <h1 style="font-size:14pt">Validation demo</h1>
  <ValidationObserver ref="observer" v-slot="dirty">
    <span :hidden="dirty"></span>
    <form @submit.prevent='onSubmit'>
      <ContentBlock :hideInstructions="true">
        <UIInput
          title="Alphabetic with spaces (names etc)"
          type='text'
          rules='alphaSpaces'
          vid="name"
          name="Name"
          v-model="formData.name"
        />
        Similar rules to alphaSpaces are alpha, alphaNumeric, alphaNumericRich (allows dashes and underscores), numeric.<br>
        <input :value="formData.name" disabled="disabled"/>

        <UIInput
          title="Email"
          type='text'
          rules='required|email'
          vid="email"
          :required="true"
          v-model="formData.email"
        />
        <UIInput
          title="Password"
          vid="password"
          name="Password"
          type='password'
          rules='required|min:6|max:12|requireNumber|requireSpecial'
          :required="true"
          v-model="formData.password"
        />
        Min length, max length, requires number and special character.

        <UIInput
          title="Confirm password"
          vid="confirmation"
          name="confirmation"
          type='password'
          rules='required|confirmed:@password'
          :required="true"
        />

        <UISelect name="Gender" title="Dropdown example (gender etc)" rules='dropdown' :required='true' v-model="formData.gender">
          <option value="'male'">{{ 'Male' }}</option>
          <option value="'female'">{{ 'Female' }}</option>
          <option value="'other'">{{ 'Other' }}</option>
        </UISelect>
        Dropdown rule requires a value to be selected
      </ContentBlock>
      <br><br><br><br><br>

      <ContentBlock :hideInstructions="true">
        <AddressInputs
          :countries="countries"
          :fullAddress="fullAddress"
        />
      </ContentBlock>
      <ContentBlock :hideInstructions="true">
        <UIInput
          title="Number range"
          vid="nr-range"
          name="Number range"
          type='number'
          rules='between:10,100|requireNumber'
        />
        <UIInput
          title="Custom local validation function"
          vid="nr-range"
          name="Number range"
          type='text'
          rules='customLocal'
        />
        Custom local function (returns true if string = "Christmas" or if it is Dec. 25th)
      </ContentBlock>

      <ContentBlock :hideInstructions="true">
        <UICheckbox
            title="Accept Terms and Conditions"
            name="Terms and Conditions"
            rules="required"
            :required="true"
            v-model="formData.acceptsTerms"
        />
        <br>

        <UIMultiselect
          :title="'Item select'"
          :taggable="true"
          :multiple="true"
          :options="['a','b','c','d']"
          :placeholder="'Select at least 2'"
          :showNoResults="false"
          :value="value"
          v-model="formData.tags"
          name="tags"
          rules="length:2|required"
        />

        <UIFormItem :title="'Access Level'" required>
          <div class="radio-grid">
            <UIRadio
              :title="'Cat'"
              name="cat"
              :value="radioValue === 'cat'"
              :instructions="'Cats eat mice'"
              @change="toggleSelected('cat')"
              rules="required"
            />
            <UIRadio
              :title="'Mouse'"
              name="mouse"
              :value="radioValue === 'mouse'"
              :instructions="'Mice eat cheese'"
              @change="toggleSelected('mouse')"
            />
            <UIRadio
              :title="'Cheese'"
              name="cheese"
              :value="radioValue === 'cheese'"
              :instructions="'Cheese eats cats'"
              @change="toggleSelected('cheese')"
            />
          </div>
        </UIFormItem>

      </ContentBlock>
      <ContentBlock :hideInstructions="true">
        <UIRange
        :title="'Range'"
        rules="required|minValue:50"
        name="Range"
        :value="40"
        max="90"
        min="20"
        />
        <br><br><hr><br>
        <UISwitch
            :title="'Use waivers on this location'"
            :value="formData.waiversRequired"
            @change="printFormData($event)"
            rules="required"
            required
        />
      </ContentBlock>

      <ContentBlock :hideInstructions="true">
        <UITimepicker :title="'Time picker minimum time'" rules="time|minTime:12:05 PM" />
        <UITimepicker :title="'Time picker maximum time'" rules="time|maxTime:12:05 PM" />
        <UIDatepicker :title="'Date before'" name="beforeDate"  rules="date|minDate:2021-07-27"/>
        <UIDatepicker :title="'Date after'" name="afterDate" rules="date|maxDate:2021-07-29"/>
      </ContentBlock>

      <ContentBlock :hideInstructions="true">
        <UIBirthdatePicker title="Birthdate picker" required/>
        <br>

        <div class="billboards">
          <UIFormItem :title="$t('Upload your own')">
            <div class="imagegrid onecolumn">
              <UIRadioThumbnail class="coverimgselected dib w-100 h-100" v-if="imageUrl" name="image" :value="imageUrl" v-model="imageUrl" :style="{ backgroundImage: `url(${imageUrl})`}"></UIRadioThumbnail>
              <div>
                <UIFileUpload @change="imageChange" rules="image"></UIFileUpload>
                <small class="f-instructions">{{ $t('16:9 ratio recommended') }}</small>
              </div>
            </div>
          </UIFormItem>
        </div>
      </ContentBlock>
      <input type='submit'>
    </form>
    <br>
    <button @click="printFormData()"> Print form data. </button>
    <br>
    <button @click="dirtyFields()"> List dirty fields. </button>
    Function serves as demo to show how to access form/field flags programatically. Can be easily adjusted to get any other field value. These include valid, invalid, changed, touched, untouched, pristine, dirty, pending, required, validated, passed and failed.
  </ValidationObserver>

  <br><br><br><hr><br><br><br>
  <h3 style="font-size:14pt">Flag demo - try typing in the field</h3>
  <ValidationProvider
    name="flagdemo"
    rules="required"
    type='text'
    v-slot="{ valid, invalid, changed, touched, untouched, pristine, dirty, pending, required, validated, passed, failed }"
  >
    <input type="text" v-model="vmodelPlaceholder">
    <ul class="BooleanSwitches">
      <li :class="`is-${valid}`">valid: {{ valid }}</li>
      <li :class="`is-${invalid}`">invalid: {{ invalid }}</li>
      <li :class="`is-${changed}`">changed: {{ changed }}</li>
      <li :class="`is-${touched}`">touched: {{ touched }}</li>
      <li :class="`is-${untouched}`">untouched: {{ untouched }}</li>
      <li :class="`is-${pristine}`">pristine: {{ pristine }}</li>
      <li :class="`is-${dirty}`">dirty: {{ dirty }}</li>
      <li :class="`is-${pending}`">pending: {{ pending }}</li>
      <li :class="`is-${required}`">required: {{ required }}</li>
      <li :class="`is-${validated}`">validated: {{ validated }}</li>
      <li :class="`is-${passed}`">passed: {{ passed }}</li>
      <li :class="`is-${failed}`">failed: {{ failed }}</li>
    </ul>
  </ValidationProvider>
  </div>
</template>

<style scoped>
.BooleanSwitches {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  box-sizing: border-box;
  column-count: 3;
}
.BooleanSwitches li {
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s ease-in-out;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
}
.BooleanSwitches li.is-true {
  background-color: #0ae569;
  color: #045929;
}
.BooleanSwitches li.is-false {
  background-color: #ffa4a2;
  color: #eb0600;
}
.BooleanSwitches li + li {
  margin-top: 10px;
}
</style>

<style scoped lang="postcss">
@import "core/shared/styles";

.experience-launcher-settings {
  & .body-content {
    @apply --f5;
  }
  & .label {
    @apply --f8;
  }
  & .imagegrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingSm);
    /* grid-auto-rows:  */
    grid-auto-rows: minmax(120px, max-content);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .skylandoptionsgrid {
    display: grid;

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
  & .launcherthumbnail {
    height: 120px;
  }
  & .onecolumn {
    grid-template-columns: 1fr;
  }
  & .equipment-select {
    display: grid;
    grid-gap: 1rem;
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingSm);
    }
    /* grid-auto-rows: 120px; */
  }
  & .billboards {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingMd);
    }
    /* grid-auto-rows: 120px; */
  }
  & .launcher-settings {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacingMd);
    }
  }
  & .previewbtn {
    @apply --buttonSecondary;
  }
  & .closebtn {
    @apply --buttonTinyPrimary;
    & >>> .icon {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
  & .title {
    @apply --f2;
  }
  & .cover {
    background-position: 0;
  }
}
</style>
