export default class FormHelper {
  observer;

  constructor (observer) {
    this.observer = observer;
  }

  get valid () {
    this.observer.validateWithInfo().then(({ isValid, validationErrors }) => {
      if (isValid) return true;
      else {
        this.errors = validationErrors;
        return false;
      }
    });
  }

  get isDirty () {
    return this.observer.flags.dirty;
  }

  get getDirty () {
    const fields = {};
    // Get field dirtyness
    for (const field in this.observer.refs) {
      fields[field] = this.observer.refs[field].flags.dirty;
    };
    return fields;
  }

  getFieldState (field) {
    return this.observer.refs[field].flags;
  }

  get loading () {
    return this.observer.flags.pending;
  }

  async created () {
  };
}
