<script>
import Component, { mixins } from 'vue-class-component';

// import Vue from 'vue';
import localizeFlatpickr from '../../localization/flatpickr';
import Flatpickr from 'flatpickr';
import ModelMixin from './ModelMixin';
import moment from 'moment';
import UiFormItem from './UIFormItem';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'UITimepicker',
  props: {
    value: {
      type: String,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    utc: {
      default: false,
    },
    twentyFourHourTime: {
      type: Boolean,
      default: false,
    },
    options: {
      default: () => ({}),
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined,
    },
    format: String,
    ...UiFormItem.options.props,
  },
  components: {
    UiFormItem,
    ValidationProvider,
  },
})
export default class UITimepicker extends mixins(ModelMixin) {
  get toMoment () { return this.utc ? moment.utc : moment; }
  get internalMoment () {
    return this.toMoment(this.internalValue, this.format);
  }

  get internalDate () {
    const date = new Date('YYYY-MM-DDTHH:mm:ss');
    date.setFullYear(this.internalMoment.format('YYYY'));
    date.setMonth(this.internalMoment.format('MM'));
    date.setDate(this.internalMoment.format('DD'));
    date.setHours(this.internalMoment.format('HH'));
    date.setMinutes(this.internalMoment.format('mm'));
    date.setSeconds(this.internalMoment.format('ss'));
    // We do the above to remove tz offset
    return date;
  }

  onFlatpickrChange (date) {
    const splitDate = date[0].toString().split(' ');
    const splitTime = splitDate[4].split(':');
    this.internalValue = moment().set({
      hours: splitTime[0],
      minutes: splitTime[1],
      seconds: splitTime[2],
    }).format(this.format);

    this.$emit('change', this.internalValue);
    this.$emit('update', this.internalValue);
  }

  onFlatpickrOpen (date, formatted) {
    if (!formatted) {
      this.internalValue = moment().set({
        hours: 12,
        minutes: 0,
        seconds: 0,
      }).format(this.format);
      this.$emit('change', this.internalValue);
      this.$emit('update', this.internalValue);
    } else {
      this.onFlatpickrChange(date);
    }
  }

  created () {
    const promise = localizeFlatpickr(this.locale);
    this.$nextTick(async () => {
      await promise;
      const input = this.$refs.input;
      const options = {
        altInput: true,
        dateFormat: this.twentyFourHourTime ? 'H:i' : 'h:i K',
        altFormat: this.twentyFourHourTime ? 'H:i' : 'h:i K',
        time_24hr: this.twentyFourHourTime,
        enableTime: true,
        noCalendar: true,
        onChange: this.onFlatpickrChange,
        onOpen: this.onFlatpickrOpen,
        defaultDate: this.value ? this.internalDate : '12:00:00',
        ...this.options,
      };
      if (this.locale) options.locale = this.locale.split('-')[0];
      this._flatpicker = Flatpickr(input, options);
      this.$watch('options', () => {
        if (!this._flatpicker) return;
        Object.keys(this.options).map((k) => {
          this._flatpicker.set(k, this.options[k]);
        });
      }, { deep: true });
    });
  }

  destroyed () {
    if (this._flatpicker) this._flatpicker.destroy();
  }
};
</script>

<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <UiFormItem :title="title" :instructions="instructions" :required="required" :root="true">
      <input class="ui-timepicker" ref="input" type="text" v-model="internalValue" />
    </UiFormItem>
    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<style lang="postcss">
@import "../../styles";

.ui-timepicker {
  @apply --text;
  min-width: 100%;
  width: 100%; /* default input size attributes makes the field overflow its parent sometimes */
}
</style>
<style lang="postcss">
@import "../../styles";

.flatpickr-calendar {
  font-family: var(--fontSansSerif);
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}
</style>
