<template>
  <ValidationObserver ref="validationObserver" tag="div">
    <UIFormItem :title="title" :root="true" class="birthdate-picker" :required="required">
      <div class="formgroup">
        <UIInput
          class="input daysinput"
          :placeholder="$t('Day')"
          v-model="inputs.day"
          :class="{ 'order-2': mdyFormatting }"
          type="text"
          :fieldNameOverride="$t('Day')"
          :rules="`${required ? 'required|' : ''}numeric|maxValue:31|minValue:1`"
          :required="required"
        />

        <UISelect
          :fieldNameOverride="$t('Month')"
          :rules="`${required ? 'required|' : ''}dropdown`"
          :required="required"
          v-model="inputs.month"
          class="input monthsinput"
          :class="{ 'order-1': mdyFormatting }"
          :placeholder="$t('Month')"
        >
          <option v-for="({ value, name }) in months" :key="name" :value="value">{{ name }}</option>
        </UISelect>

        <UIInput
          :placeholder="$t('Year')"
          class="input yearsinput"
          v-model="inputs.year"
          :class="{ 'order-3': mdyFormatting }"
          type="text"
          :fieldNameOverride="$t('Year')"
          :rules="`${required ? 'required|' : ''}numeric|minValue:1900`"
          :required="required"
        />
      </div>
    </UIFormItem>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import Component from 'vue-class-component';
import UIFormItem from './UIFormItem';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    UIFormItem,
    UIInput,
    UISelect,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    ...UIFormItem.options.props,
    value: [String, Date],
    mdyFormatting: Boolean,
  },
  model: {
    event: 'change',
  },
  watch: {
    inputs: {
      deep: true,
      handler: 'onInput',
    },
    value: {
      immediate: true,
      handler (val) {
        if (!val) return;
        const momentDate = moment(val);
        if (!momentDate.isValid()) return;
        const { years: year, months: month, date: day } = momentDate.toObject();
        this.inputs = {
          year,
          month,
          day,
        };
      },
    },
  },
})
export default class UIBirthdatePicker extends Vue {
  months = moment.months().map((name, i) => ({
    name,
    value: i,
  }));

  inputs = {
    day: '',
    month: '',
    year: '',
  };

  focus=false;

  get momentDate () {
    return moment(this.inputs);
  }

  async onInput () {
    this.$emit('input');
    let { isValid, errors } = await this.$refs.validationObserver.validateWithInfo({ silent: true });
    isValid &= this.momentDate.isValid();
    // NOTE(Jack): Just casting this to a boolean to make sure everything works properly after the bitwise operation
    isValid = Boolean(isValid);
    if (isValid) {
      this.$emit('change', this.momentDate.format());
    } else {
      this.$emit('invalid', errors);
    }
  }

  onBlur () {
    this.focus = false;

    // Weirdly using this.$nextTick(); wasn't working. so I hacked-in setTimeout
    setTimeout(() => {
      if (!this.focus) this.$emit('blur');
    }, 0);
  }

  setFocusTrue () {
    this.focus = true;
  }
}
</script>

<style scoped lang="postcss">
@import '../../styles';

.birthdate-picker {
  & .formgroup {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: var(--spacingSm);

    & .-error {
      border-color: var(--colorErrorFlat);
      box-shadow: none;
      border-width: 2px;
    }

    & select {
      width: 100%;
      @apply --select;
      &:invalid { color:#707070; }
    }
    & input {
      width: 100%;
      @apply --text;
    }
    & > *:last-child {
      margin-right: 0!important;
    }
    & .order-1 {
      grid-column: 1 / 2;
    }
    & .order-2 {
      grid-column: 2 / 3;
    }
    & .order-3 {
      grid-column: 3 / 4;
    }
  }
}
</style>
